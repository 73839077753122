import React, {useState} from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import { Input, Table, Slider, Button } from 'antd';
import * as queryString from "query-string";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import buildUrl from 'build-url';


const numChangesMarks = {
  0:  '1',
  20: '2',
  40: '4',
  60: '6',
  80: '8',
  100: '10'
};

const testTimeMarks = {
  0:  '0.25 hr',
  20: '0.5 hr',
  40: '1 hr',
  60: '1.5 hrs',
  80: '3 hrs',
  100: '4 hrs'
};


const diffSavingsMarks = {
  0:  '5%',
  20: '20%',
  40: '40%',
  60: '60%',
  80: '80%',
  100: '100%'
};



const resultColumns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Total Annual Savings',
    dataIndex: 'total',
    key: 'total',
    align: 'center'
  },
  {
    title: 'Annual Savings per Developer',
    dataIndex: 'per_developer',
    key: 'per_developer',
    align: 'center'
  },
];



function URLButton({location, numDevs, salary, overhead, numChanges, testTime, diffSavings}) {

  const [copied, setCopied] = useState(false)
  const url = buildUrl(location.origin, {
    path: location.pathname,
    queryParams: {
      numDevsP: numDevs,
      salaryP: salary,
      overheadP: overhead,
      numChangesP: numChanges,
      testTimeP: testTime,
      diffSavingsP: diffSavings
    }
  });

  return (
          <div>
          <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
            <Button type="dashed">Copy link to clipboard</Button>
          </CopyToClipboard>
          {copied ? <span> Copied!</span> : null}
          </div>
          )

}

function Calculator({location}) {
    console.log(location);
    const { numDevsP, salaryP, overheadP, numChangesP, testTimeP, diffSavingsP} = queryString.parse(location.search);

    const [numDevs, setNumDevs] = useState((numDevsP === undefined)             ? 5 : numDevsP);
    const [salary, setSalary] = useState((salaryP === undefined)                ? 120000 : salaryP);
    const [overhead, setOverhead] = useState((overheadP === undefined)          ? 2.7 : overheadP);
    const [numChanges, setNumChanges] = useState((numChangesP === undefined)    ? 40 : numChangesP);
    const [testTime, setTestTime] = useState((testTimeP === undefined)          ? 40 : testTimeP);
    const [diffSavings, setDiffSavings] = useState((diffSavingsP === undefined) ? 80 : diffSavingsP);

    const hourlyDevCost = parseFloat(overhead)
                        * parseFloat(salary)
                        / 2080

    const timeSaved = parseInt(numChangesMarks[numChanges])
                      * 52 // for every week
                      * testTimeMarks[testTime].split(' ')[0]
                      * diffSavingsMarks[diffSavings].split('%')[0]/100

    const resultRows = [
      {
        key: '1',
        name: 'Time (hours)',
        total:  `${(parseInt(numDevs) * timeSaved).toFixed(0)}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        per_developer: `${timeSaved.toFixed(0)}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
      },
      {
        key: '2',
        name: 'Costs ($)',
        total: `$${(hourlyDevCost * parseInt(numDevs) * timeSaved).toFixed(0)}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        per_developer: `$${(hourlyDevCost * timeSaved).toFixed(0)}`
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
    ];
    return (
    <Container>
        <SEO title="Data Diff Value Calculator"/>
        <h1>Data Diff value calculator</h1>
        <p className="calcDesc">This is a simple model for estimating economic impact of adopting the Data Diff tool at your organization. </p>
        <div className="calc">
            <p className="desc">How many data developers (Data Engineers, Analysts etc.) does your organization have?</p>

            <Input name="numDevs" onChange={e => setNumDevs(e.target.value)} defaultValue={numDevs}/>

            <p>What is the average annual salary for a data developer in your organization?</p>

            <Input name="salary" prefix="$" onChange = {e => setSalary(e.target.value)} defaultValue={salary}/>

            <p>Labor overhead coefficient (<a href="https://web.mit.edu/e-club/hadzima/how-much-does-an-employee-cost.html">source</a>)</p>

            <Input name="overhead" onChange={e => setOverhead(e.target.value)} defaultValue={overhead}/>

            <p>How many code changes (pull requests) does a data developer deploy on average per week?</p>

            <Slider name="numChanges" defaultValue={numChanges} marks={numChangesMarks} step={20} tooltipVisible={false}
                    onChange={e => setNumChanges(e)}/>

            <p>How much time does a data developer spend to test an average change?</p>

            <Slider name="testTime" defaultValue={testTime} marks={testTimeMarks} step={20} tooltipVisible={false}
                    onChange={e => setTestTime(e)}/>

            <p>What % of manual testing can be automated with Data Diff?</p>

            <Slider name="diffSavings" defaultValue={diffSavings} marks={diffSavingsMarks} step={20} tooltipVisible={false}
                    onChange={e => setDiffSavings(e)}/>

            <Table className="calcResultTable" dataSource={resultRows} columns={resultColumns} pagination={false} />

        </div>
        <URLButton location={location} numDevs={numDevs} salary={salary} overhead={overhead} numChanges={numChanges} testTime={testTime} diffSavings={diffSavings}/>
    </Container>
    )
}

export default Calculator
